import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header
    style={{
      borderBottom: `#ebebeb solid 1px`,
      background: `#fcfdff`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1rem 1rem 0 1rem`,
      }}
    >
      <h1 style={{
        margin: `0 0 .5rem 0`,
        textAlign: `center`,
        fontSize: `40px`,
      }}>
        <Link
          to="/"
          style={{
            color: `#1c333d`,
            textDecoration: `none`,
            borderBottom: `#ebebeb solid 1px`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <ul
      style={{
        display: `flex`,
        fontFamily: `Montserrat, sans-serif`,
        fontSize: `14px`,
        justifyContent: `center`,
        margin: 0,
      }}>
        <li
          style={{
            flex: `0 1 auto`,
            paddingRight: `.5em`,
            borderRight: `#ebebeb solid 1px`,
            borderBottom: `none`,
            listStyle: `none`,
          }}>
          <Link
            className="header-link"
            to="/about"
            style={{color: `#6d8d9c`}}>About</Link></li>
        <li
          style={{
            flex: `0 1 auto`,
            borderRight: `#ebebeb solid 1px`,
            padding: `0 .5em 0 .5em`,
            listStyle: `none`,
          }}>
          <Link
            className="header-link"
            to="/resume"
            style={{color: `#6d8d9c`}}>Resume</Link></li>
        <li  style={{
          flex: `0 1 auto`,
          borderRight: `#ebebeb solid 1px`,
          padding: `0 .5em 0 .5em`,
          listStyle: `none`,
        }}>
          <Link
            className="header-link"
            to="/selected-projects"
            style={{ color: `#6d8d9c`}}>Selected Projects</Link></li>
        <li  style={{
          flex: `0 1 auto`,
          paddingLeft: `.5em`,
          borderBottom: `none`,
          listStyle: `none`,
        }}>
          <Link
            className="header-link"
            to="/"
            style={{ color: `#6d8d9c`}}>Notes</Link></li>
      </ul>

    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
